import React from 'react'
import Layout from 'components/Layout'
import { injectIntl} from "gatsby-plugin-intl"


const SuccessPage = ({ intl }) => {

  const isThai = () => {
    return (intl.locale === "th")
  }
  const isRussian = () => {
    return (intl.locale === "ru")
  }
  const isEnglish = () => {
    return (intl.locale === "en")
    }
  return (
  <Layout>

    <div id="main" className="alt">
      <section id="one">
        <div className="container text-center">
           {
             (isEnglish(intl)) && <div className="form-submission-text mt-5">
              <h1 className="pt-5 mb-5">Thank you for getting in touch!</h1>
              <p>We appreciate you contacting us about your future education. One of our consultants will get back to you shortly*.</p>
              <p>We are looking forward to seeing you on our consultation -</p>
              <p>the first step to your dream university.</p>
              <p>Have a great day!</p>
          
          
              <p><a href="https://drive.google.com/file/d/1YKG9f2tMYrqkW5zsvntKrm59nBqiPDZA/view?usp=sharing">Download free e-book.</a></p>
          
          
          
             <p><em>* Please note that during a peak season up to 3 working days may be required.</em></p><p></p>
              </div>
           }

          { (isRussian(intl)) && <div className="form-submission-text mt-5">
             <h1 className="pt-5 mb-5">Спасибо что связались с нами!</h1>
              <p>Мы ценим то, что Вы обратились к нам с вопросами о Вашем будущем образовании. Наши консультанты свяжутся с Вами в ближайшее время*.</p>
              <p>Мы с нетерпением ждём встречи с Вами на нашей консультации -</p>
              <p>это первый шаг к университету Вашей мечты.</p>
             <p>Хорошего дня!</p>
          
          
             <p><a href="https://drive.google.com/file/d/1YKG9f2tMYrqkW5zsvntKrm59nBqiPDZA/view?usp=sharing">Получить полезный файл в подарок.</a></p>
             <p><em>* *Пожалуйста, обратите внимание, что может потребоваться до 3-х рабочих дней.</em></p><p></p>
              </div>
          } 

         { (isThai(intl)) && <div className="form-submission-text mt-5">
          <h1>ขอบคุณที่สนใจให้ซิแกมเป็นส่วนสำคัญในการทำความฝันของคุณ</h1>
         <p>ทางทีมงานที่ปรึกษาของซิแกมจะติดต่อคุณในระยะเวลาอันสั้น</p>
         <p>ข้อความนี้เป็นการแจ้งข้อมูลผ่านระบบอัตโนมัติ หากท่านพบว่ามีข้อมูล</p>
         <p>หรือสิ่งผิดปกติใดๆ ที่ไม่ถูกต้อง กรุณาติดต่อบริษัท ซิแกม โกลบอล จำกัด</p>
         <p>+66 2-231-8341</p>
          </div>
          }

        </div> 
      </section>
    </div>
  </Layout>


  
)
        }
export default injectIntl(SuccessPage)

/* ขอบคุณที่สนใจให้ซิแกมเป็นส่วนสำคัญในการทำความฝันของคุณ

ทางทีมงานที่ปรึกษาของซิแกมจะติดต่อคุณในระยะเวลาอันสั้น

ข้อความนี้เป็นการแจ้งข้อมูลผ่านระบบอัตโนมัติ หากท่านพบว่ามีข้อมูล
หรือสิ่งผิดปกติใดๆ ที่ไม่ถูกต้อง กรุณาติดต่อบริษัท ซิแกม โกลบอล จำกัด 
+66 2-231-8341 */